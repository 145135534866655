import 'core-js/stable';
import 'whatwg-fetch';
import './Sass/base.scss';

import React, {
  Suspense, lazy, useState, useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import i18n from './Translations/i18n';
import PropsRoute from './Containers/PropsRoute';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { getAppConfig } from './reducers/appConfig';

const Logout = lazy(() => (import('./Components/Logout')));
const AppWithAuth = lazy(() => (import('./AppWithAuth')));

const fetchApiData = async () => {
  try {
    await getAppConfig(); // Replace with your API URL
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  // Fetch data on component mount
  useEffect(() => {
    const loadData = async () => {
      await fetchApiData();
      setLoading(false); // Set loading to false when the API call completes
    };
    loadData();
  }, []);
  // While loading, don't render anything
  if (loading) {
    return null; // You can also return a loading spinner here if needed
  }
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Suspense fallback={<React.Fragment />}>
            <AppWithAuth />
            <PropsRoute
              path="/logout"
              component={Logout}
            />
          </Suspense>
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};
ReactDOM.render(
  <AppWrapper />,
  document.getElementById('root'),
);

registerServiceWorker();
